<template>
    <ul class="d-flex flex-column list-group" v-if="showChecklists">
        <checklist v-for="(checklist, index) in jsonData.checklists" :checklist="checklist" :itemRow="itemRow" 
            @saveChecklist="saveItemJsonData(dataObject, itemRow, jsonData)"
            @deleteChecklist="deleteChecklist(index)"/>
    </ul>
</template>

<script setup>
    import { ref, computed, nextTick } from 'vue';
    import { getDataObjectById } from 'o365.vue.ts';
    import DataItem from 'o365.modules.DataObject.Item.ts';
    import Checklist from 'o365.vue.components.Checklists.Checklist.vue'; 
    import { saveItemJsonData } from 'o365.vue.components.Checklists.common.js';
    import o365_confirm from 'o365.controls.confirm.ts';

    const props = defineProps({
        itemRow: {
            type: Object,
            required: true
        },
        dataObject: {
            type: Object,
            required: true
        }
    });


    const jsonData = ref(null);
    initializeJsonData();

    const showChecklists = computed(() => {
        return props.itemRow.PrimKey && jsonData.value?.checklists?.length > 0
    });

    function deleteChecklist(index) {
        const options = {
            message: 'Are you sure you want to delete this Checklist?',
            title: 'Delete confirm',
            btnTextOk: 'Delete',
            btnClassOk: 'btn-danger',
            zIndex: 1060
        };
        o365_confirm(options).then(() => {
            jsonData.value.checklists.splice(index, 1);
            saveItemJsonData(props.dataObject, props.itemRow, jsonData.value);
        }).catch(_ => {});
    }

    function addChecklist() {
        if(!jsonData.value) {
            initializeJsonData();
        }
        jsonData.value.checklists.push({
            checklistItems: [],
            name: new DataItem(0, {
                Name: "Checklist",
            }, {}),
            isNewRecord: true
        });
    }

    async function createChecklist() {
        const jsonData = props.itemRow.JsonData ? JSON.parse(props.itemRow.JsonData) : {};
        if(!jsonData.checklists) {
            jsonData.checklists = [];

            saveItemJsonData(props.dataObject, props.itemRow, jsonData);
        }

        addChecklist();
    }

    function initializeJsonData() {
        jsonData.value = JSON.parse(props.itemRow.JsonData)
        jsonData.value?.checklists?.forEach((checklist, idx) => {
            let checklistItemObjects = [];
            checklist.checklistItems.forEach((checklistItem, idx) => {
                const item = new DataItem(idx, {
                    Name: checklistItem.name,
                    value: checklistItem.value
                }, {});
                checklistItemObjects.push(item);
            });
            checklist.name = new DataItem(0, {
                Name: checklist.name,
            }, {});
            checklist.checklistItems = checklistItemObjects;
        });
    }

    window.JsonData = jsonData.value;
    defineExpose({createChecklist});
</script>